<template>
  <div id="workflow-reports">
    <Portal to="action-bar">
      <keep-alive>
        <ItemActions
          :id="workflowId"
          item="workflow-report"
          :columns="[]"
          group-by=""
          order=""
          :filter-by="[]"
          criteria=""
          active-view=""
          :hide-actions="['toggle', 'sort', 'group', 'filter', 'activeView']"
          :dashboard-view.sync="activeView"
          page="REPORT"
          :simple-search-filter="true"
          @refresh="refreshData"
          @search="searchRow"
          @export="export_Data"
          @filter="getData"
        />
      </keep-alive>
    </Portal>

    <SimpleFilter
      :id.sync="workflowId"
      :columns="_columns"
      :filter-by.sync="filterBy"
      name="WORKFLOW"
      :list="workflowList"
      :form.sync="form"
      :form-fields="formFields"
      module="form"
      :module-id="formId"
      :active-view="activeView"
      :fid.sync="filterId"
      :wfilter-list="WfilterList"
      :no-filter="noFilter"
      @update="getWorkflowData"
      @saveData="saveSheet"
      @viewFilter="showFilterList = true"
    />

    <BaseLoadingBarSheet v-if="loadingBarContent" class="loading-content" />

    <SaveSheet
      v-model="issavesheet"
      :filter="filterBy"
      :repository-id="workflowId"
      :page="'workflowReport'"
      :edit="editData"
      @success="success = true"
    />
    <div v-if="showFilterList && filterId === '-1'" class="col-12">
      <div>
        <Breadcrumbs :breadcrumbs="breadcrumb" @reset="homeRepository" />
      </div>
      <q-space />
    </div>
    <div v-if="showFilterList && filterId === '-1'">
      <FilterListType
        :mode="mode"
        :items="savedItems"
        :columns="columnss"
        :filter-list="savedList"
        @view="openFilter"
        @isDelete="deleteItem"
      />
    </div>

    <div v-else class="">
      <template v-if="activeView === 'TABLE'">
        <template v-if="workflowRequestList.length">
          <ReportList
            :mode="mode"
            :items="items"
            :columns="columns"
            :lists="list"
            :form="form"
            @open="openProcess"
            @history="openHistory"
            @attachments="openAttachments"
            @comments="openComments"
            @viewFile="openFile"
            @openSubWorkflow="updateSelectedSubWorkflow"
            @subHistory="showSubHistory"
            @subComments="openSubComments"
            @subAttachments="openSubAttachments"
          />
        </template>
        <template v-else-if="workflowId">
          <template v-if="!loadingBarContent">
            <StateWrapper
              icon="o_account_tree"
              :title="title"
              description="No data available at the moment"
              style="margin-top: 20px"
            />
          </template>
        </template>
        <template v-else-if="!loadingBarContent">
          <StateWrapper
            icon="o_account_tree"
            title="Generate Report"
            description="Select any workflow to display data"
            style="margin-top: 20px"
          />
        </template>
      </template>
      <template v-else-if="activeView === 'CHART'">
        <ReportCharts
          :workflow-id="workflowId"
          :workflow-stage="stages"
          :form-id="formId"
          :form-fields="formFields"
          :sla-settings="slaSettings"
          :repository-id="repositoryId"
          :repository-fields="repositoryFields"
          :workflow="workflow"
          :refresh.sync="chartRefresh"
        />
      </template>
    </div>

    <Pagination
      v-if="
        (workflowRequestList.length && activeView === 'TABLE') ||
        filterId === '-1'
      "
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
      :file-sheet="hideFileSheet"
    />

    <!-- form -->

    <Sheet
      v-model="processSheet"
      width="100vw"
      no-padding
      @input="processSheet = false"
    >
      <!-- title -->

      <template #title> {{ headerTitle }} </template>

      <!-- ... -->

      <!-- actions -->

      <template #actions>
        <BaseActionButton
          v-if="itemModel.itemId"
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="processDocumentAction(action)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
        <template v-if="formId">
          <BaseActionButton
            v-if="!formView"
            v-tooltip.top="'Form View'"
            is-flat
            :icon="'mdi-form-select'"
            class="q q-mr-sm"
            @click="formView = true"
          />

          <BaseActionButton
            v-else
            v-tooltip.top="'Details View'"
            is-flat
            :icon="'mdi-table-column'"
            class="q-mr-sm"
            @click="formView = false"
          />
        </template>
      </template>

      <!-- ... -->

      <!-- content -->

      <div id="content-wrapper" class="row">
        <div v-if="formId" class="col">
          <BaseScrollbar height="calc(100vh - 60px)">
            <div v-if="formView" id="form-view">
              <RenderForm
                v-if="formId && form"
                :form-id="formId"
                :panels="panels"
                :secondary-panels="secondaryPanels"
                :form-settings="formSettings"
                :form-model="formModel"
                :save-action.sync="saveAction"
                :workflow-form-model.sync="workflowFormModel"
                :is-readonly="true"
                :enable-controls="_enableControls"
              />
            </div>
            <div v-else class="q-pa-md">
              <!-- <div class="col-12 q-mb-sm q-pt-md stepper">
                <Stepper
                  :stage-level="stageLevel"
                  :current-step="currentStep"
                />
              </div> -->
              <div id="requestDetails" class="col-12">
                <div class="row justify-center">
                  <div class="col">
                    <div class="row items-center">
                      <table class="submittedData q-mb-sm">
                        <tbody>
                          <tr v-if="selectedProcess.createdByName">
                            <td colspan="4">
                              Requested By:
                              {{ selectedProcess.createdByName }}
                            </td>
                          </tr>
                          <tr>
                            <th v-if="selectedProcess.raisedBy">
                              Requested User Email
                            </th>
                            <th>Requested Date</th>
                            <th>Last Acted By</th>
                            <th>Last Acted On</th>
                          </tr>
                          <tr>
                            <td v-if="selectedProcess.raisedBy">
                              {{ selectedProcess.raisedBy }}
                            </td>
                            <td>
                              {{ $day.format(selectedProcess.raisedAt) }}
                            </td>
                            <td>
                              {{ selectedProcess.transaction_createdByEmail }}
                            </td>
                            <td>
                              {{
                                $day.format(
                                  selectedProcess.transaction_createdAt
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <BaseScrollbar height="calc(100vh - 275px)">
                        <template>
                          <table class="mainData col-12">
                            <template
                              v-for="(value, key) in selectedProcess.formData
                                .fields"
                            >
                              <template v-if="value && value != '[]'">
                                <template v-if="validateType(key) !== 'TABLE'">
                                  <tr :key="`${key}-${value}`">
                                    <td class="tblHeader ellipsis small">
                                      {{ getLabel(key) }}
                                    </td>
                                    <td class="ellipsis">
                                      <template
                                        v-if="
                                          validateType(key) === 'FILE_UPLOAD'
                                        "
                                      >
                                        <div v-if="JSON.parse(value).length">
                                          <div
                                            v-for="file in JSON.parse(value)"
                                            :key="file.fileName"
                                            class="q-mb-xs"
                                          >
                                            <div class="row items-center">
                                              <div
                                                class="col row items-center"
                                                @click="viewFile(file, value)"
                                              >
                                                <FileIcon
                                                  :mime-type="
                                                    fileType(file.fileName)
                                                  "
                                                  class="mini-avatar q-mr-sm"
                                                />
                                                <span
                                                  class="cursor-pointer text-underline text-secondary"
                                                  >{{ file.fileName }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </template>
                                      <template
                                        v-else-if="validateType(key) === 'DATE'"
                                      >
                                        {{ $day.format(value, false) }}
                                      </template>
                                      <template
                                        v-else-if="validateValue(value)"
                                      >
                                        {{ removeTags(value) || "-" }}
                                      </template>
                                      <template v-else>
                                        <a :href="value" target="_blank">
                                          {{ value || "-" }}
                                        </a>
                                      </template>
                                    </td>
                                  </tr>
                                </template>
                                <template v-else-if="JSON.parse(value).length">
                                  <tr
                                    v-if="getLabel(key)"
                                    :key="'trlabel-' + key"
                                  >
                                    <td
                                      colspan="2"
                                      class="tblHeader ellipsis small"
                                    >
                                      <FormFieldLabel
                                        :key="'label-' + key"
                                        :label="getLabel(key)"
                                        class="q-mt-sm"
                                      />
                                    </td>
                                  </tr>
                                  <tr :key="'trtable1-' + key">
                                    <td colspan="2">
                                      <BaseScrollbar
                                        :key="'scroll-' + key"
                                        style="max-height: 200px"
                                      >
                                        <table
                                          :key="'table-' + key"
                                          class="sub-table q-mt-sm q-mb-sm"
                                        >
                                          <thead>
                                            <tr>
                                              <th
                                                v-for="(
                                                  field_item, field_key
                                                ) in tableValue(value)[0]"
                                                :key="field_key"
                                              >
                                                {{
                                                  getLabel(field_key, "table")
                                                }}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              v-for="(
                                                field_item, field_key
                                              ) in tableValue(value)"
                                              :key="field_key"
                                            >
                                              <td
                                                v-for="(
                                                  row_value, row_key
                                                ) in field_item"
                                                :key="row_key"
                                              >
                                                {{ row_value }}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </BaseScrollbar>
                                    </td>
                                  </tr>
                                </template>
                              </template>
                            </template>
                          </table>
                        </template>
                      </BaseScrollbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BaseScrollbar>
        </div>
        <div v-if="itemModel.itemId" class="col">
          <div class="fileFrame">
            <div class="file-list">
              <iframe
                id="file-frame"
                ref="myProcessFiles"
                :src="itemLink()"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="col-auto q-mr-sm leftBorder">
          <template v-for="tab in tabs">
            <BaseActionButton
              v-if="checkTabAccess(tab.value)"
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </template>
        </div>
      </div>

      <!-- ... -->
    </Sheet>

    <!-- ... -->

    <!-- attachments -->

    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :upload="false"
      no-padding
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          class="q-mr-xs"
          no-border
          is-flat
          size="20px"
          @click="getAttachments"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar>
          <template v-for="file in attachmentList">
            <div :key="file.id" class="attachmentList">
              <div class="title row">
                <div v-if="selectedProcess.processId" class="col-auto">
                  <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                  <BaseIcon
                    :name="
                      file.checked
                        ? 'eva-checkmark-circle-2'
                        : 'eva-radio-button-off-outline'
                    "
                    color="secondary"
                    class="q-mr-sm"
                    @click="file.checked = !file.checked"
                  />
                </div>
                <div v-if="file.formJsonId" class="col-auto">
                  <BaseIcon
                    v-tooltip:green.top="getLabel(file.formJsonId)"
                    name="mdi-form-select"
                    color="primary"
                    class="q-mr-sm"
                  />
                </div>
                <div class="col-auto">
                  <FileIcon
                    :mime-type="fileType(file.name)"
                    class="mini-avatar q-mr-sm"
                  />
                </div>
                <div class="col ellipsis" @click="openFile(file)">
                  {{ file.name }}
                </div>
                <div class="col-auto">
                  <BaseActionButton
                    v-tooltip.top="'Download'"
                    icon="eva-download-outline"
                    color="primary"
                    no-border
                    is-flat
                    size="20px"
                    @click.stop="fileMenuAction(file, 'Download')"
                  />
                </div>
                <div class="col-auto">
                  <BaseActionButton
                    v-tooltip.top="'Print'"
                    icon="eva-printer-outline"
                    color="primary"
                    no-border
                    is-flat
                    size="20px"
                    @click.stop="fileMenuAction(file, 'Print')"
                  />
                </div>
              </div>

              <div class="meta row">
                <div class="col row">
                  <!-- created at -->

                  <div>{{ $day.format(file.createdAt) }}</div>

                  <!-- ... -->

                  <!-- created by -->

                  <template v-if="file.createdBy">
                    <BaseIcon name="mdi-circle-small" />
                    <div>{{ file.createdByEmail }}</div>
                  </template>

                  <!-- ... -->

                  <!-- size -->

                  <!-- <template v-if="file.size">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ fileSize(file.size) }}</div>
              </template> -->

                  <!-- ... -->
                </div>
                <div class="col-auto">
                  <BaseActionButton
                    v-tooltip.top="'Metadata'"
                    icon="eva-file-text-outline"
                    color="secondary"
                    no-border
                    is-flat
                    size="20px"
                    @click.stop="getFileData(file)"
                  />
                </div>
              </div>
            </div>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>

    <!-- ... -->

    <!-- comments -->

    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      custom-height="30"
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 155px)">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-2'
              "
              class="q-ma-sm"
            />
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->
    </Sheet>

    <!-- ... -->

    <!-- file -->

    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div
              v-if="selectedFile.createdAt && selectedFile.createdByEmail"
              class="meta"
            >
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myAttachmentFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <!-- ... -->

    <!-- tasks -->

    <Sheet v-model="taskSheet" width="30vw" @input="closeTaskSheet">
      <!-- title -->

      <template #title> Tasks </template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
        />

        <BaseActionButton
          v-tooltip.top="'Add Task'"
          icon="mdi-plus"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="$emit('addTask')"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="task in taskEntries">
          <div :key="task.id" class="taskEntries">
            <div class="col-12">
              <div class="row">
                <div
                  class="col-auto q-mr-md"
                  style="margin-top: 3px"
                  @click="$emit('viewTask', task.id)"
                >
                  {{ task.name }}
                </div>
                <q-chip
                  size="10px"
                  :color="getColor(task.priority)"
                  text-color="white"
                >
                  {{ task.priority }}
                </q-chip>
                <q-chip
                  size="10px"
                  :color="getColor(task.status)"
                  text-color="white"
                >
                  {{ task.status }}
                </q-chip>
              </div>
            </div>

            <div class="meta row">
              <div class="col-12">Assigned To: {{ task.assigned }}</div>
              <div class="col-12">
                Start Date: {{ $day.format(task.startDate, false) }}
                <BaseIcon name="mdi-circle-small" />
                End Date: {{ $day.format(task.endDate, false) }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </Sheet>

    <!-- ... -->

    <FileDetails v-model="fileDetailsSheet" :file-data="fileData"></FileDetails>

    <Sheet
      v-model="mailTransactionSheet"
      width="30vw"
      no-padding
      @input="mailTransactionSheet = false"
    >
      <!-- title -->

      <template #title> Mail Transactions </template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getMailTransactions()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <div class="q-pl-sm">
          <Tabs v-model="mailTab" :tabs="mailTabs" />
        </div>
        <template v-if="mailTab === 'OUTGOING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in outgoingMails">
              <div :key="'outgoing' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-auto">
                        <BaseIcon
                          name="mdi-file-link-outline"
                          color="red"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto text-underline">123456987.msg</div>
                    </div> -->
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>

                    <BaseActionButton
                      v-tooltip.top="'resend'"
                      icon="mdi-send"
                      color="secondary"
                      is-flat
                      size="16px"
                      @click.stop="resendMail(row.ezMailId)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
        <template v-if="mailTab === 'INCOMING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in incomingMails">
              <div :key="'incoming' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-auto">
                        <BaseIcon
                          name="mdi-file-link-outline"
                          color="red"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto text-underline">123456987.msg</div>
                    </div> -->
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
      </div>

      <!-- ... -->
    </Sheet>
    <Sheet
      v-model="mailAttachmentSheet"
      width="30vw"
      no-padding
      @input="mailAttachmentSheet = false"
    >
      <!-- title -->

      <template #title> Mail Attachments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 200px)">
          <template v-for="file in mailAttachmentList">
            <div :key="file.id" class="attachmentList">
              <div class="title row">
                <div class="col-auto">
                  <FileIcon
                    :mime-type="fileType(file.name)"
                    class="mini-avatar q-mr-sm"
                  />
                </div>
                <div class="col ellipsis" @click="openAttachmentFile(file)">
                  {{ file.name }}
                </div>
              </div>

              <div class="meta">
                <!-- created at -->

                <div>{{ $day.format(file.createdAt) }}</div>

                <!-- ... -->

                <!-- size -->

                <template v-if="file.size">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ fileSize(file.size) }}</div>
                </template>

                <!-- ... -->
              </div>
            </div>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>

    <SubWorkflowProcess
      :workflow-id="subWorkflowId || subProcessData.id"
      :initiate-sub-workflow-process="initiateSubWorkflowProcess"
      :main-workflow-id="workflow.id"
      :main-process-id="selectedProcess.processId"
      :sub-process-link="subProcessLink"
      :selected-process="subProcessData"
      :open-sub-process="openSubProcessSheet"
      :main-form-fields="formFields"
      :main-form-model="formModel"
      @closeProcessSheet="closeSubProcess"
    />

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />

    <Sheet v-model="subProcessSheet" width="30vw" @input="closeSubProcessSheet">
      <!-- title -->

      <template #title> Linked Request </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="(process, index) in subProcessTickets">
          <div :key="index" class="subProcess">
            <div class="title row">
              <div class="col-auto">
                <BaseIcon
                  name="o_account_tree"
                  class="q-mr-sm"
                  color="primary-4"
                />
              </div>
              <div
                class="col ellipsis text-underline"
                @click="openSubProcess(process)"
              >
                {{ process.requestNo }}
              </div>
              <q-chip
                v-if="process.flowStatus === '1'"
                size="10px"
                color="green"
                text-color="white"
              >
                Completed
              </q-chip>
              <q-chip v-else size="10px" color="orange" text-color="white">
                Running
              </q-chip>
            </div>

            <div class="meta row">
              <div class="col row">
                <div>{{ process.name }}</div>
                <BaseIcon name="mdi-circle-small" />
                <div>{{ process.stage }}</div>
                <BaseIcon name="mdi-circle-small" />
                <div>{{ process.duration }} ago</div>
              </div>
            </div>
            <div v-if="false" class="col-auto q-mr-sm q-pt-md leftBorder">
              <template v-for="tab in subTabs">
                <BaseActionButton
                  :key="tab.id"
                  v-tooltip.left="tab.label"
                  is-flat
                  no-border
                  :icon="tab.icon"
                  color="primary"
                  class="linkActions"
                  size="16px"
                  @click="subProcessShowLinks(tab.value, process)"
                >
                  <span
                    v-if="
                      tab.value === 'ATTACHMENTS' && process.attachmentCount
                    "
                    class="badge"
                    >{{ process.attachmentCount }}
                  </span>

                  <span
                    v-if="tab.value === 'COMMENTS' && process.commentsCount"
                    class="badge"
                    >{{ process.commentsCount }}
                  </span>
                </BaseActionButton>
              </template>
            </div>
          </div>
        </template>
      </div>
    </Sheet>

    <ConfirmDelete
      v-model="isDeleteConfirmationVisible"
      :delete-data="deleteData"
      @delete="success = true"
    />

    <FormEntryHistory
      v-if="showFormEntryHistoryModal"
      :value.sync="showFormEntryHistoryModal"
      :workflow-id="workflow.id"
      :process-id="selectedProcess.processId"
    />

    <Attachments
      v-if="showAttachmentSheet"
      :value.sync="showAttachmentSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="'sent'"
      :initiate-process="false"
      :form-check-list="false"
      :check-list="checkList"
      :wform="wform"
      :repository-details="repositoryDetails"
      :workflow-model="{}"
      :form-upload-files="[]"
      :repository-field="repositoryFields"
      :columns="columns"
      :attachments.sync="attachmentList"
      :workflow-id="workflow.id"
      @attachmentCount="attachmentCount"
    />

    <Comments
      v-if="showCommentsSheet"
      :value.sync="showCommentsSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="'sent'"
      :initiate-process="false"
      :attachment-list="attachmentList"
      :workflow-id="workflow.id"
      :file-names="[]"
      :comments.sync="commentsList"
      :display-internal-public="false"
      @commentsCount="commentsCount"
    />
  </div>
</template>

<script>
import { Portal } from "portal-vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import SimpleFilter from "@/components/common/display/item-actions/components/SimpleFilter.vue";
import ReportList from "./components/ReportList.vue";
import {
  workflow,
  report,
  user,
  form,
  repository,
  menu,
  dashboard,
} from "@/api/factory.js";
import Pagination from "@/components/common/display/Pagination.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import Sheet from "@/components/common/popup/Sheet.vue";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
} from "@/helpers/file-format.js";
import formatBytes from "@/helpers/format-bytes.js";
import { exportData } from "@/helpers/export-excel-data";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import FileDetails from "../workflows-inbox/components/FileDetails.vue";
import ReportCharts from "./components/ReportCharts.vue";
import ListItem from "@/components/common/ListItem.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";
import SubWorkflowProcess from "@/views/workflows/views/workflows-inbox/components/components/SubWorkflow";
import FormEntryHistory from "@/components/common/workflow/FormEntryHistory.vue";
// import Stepper from "@/components/common/workflow/Stepper.vue";
import Attachments from "@/components/common/workflow/Attachments.vue";
import Comments from "@/components/common/workflow/Comments.vue";
import SaveSheet from "@/components/common/display/item-actions/components/SaveSheet.vue";
import FilterListType from "@/components/common/display/item-actions/components/FilterListType.vue";
import ConfirmDelete from "@/components/common/display/item-actions/components/DeleteSaveItems.vue";
import Breadcrumbs from "@/views/repositories/views/repository-browse-and-trash/components/Breadcrumbs.vue";

export default {
  name: "WorkflowsReport",

  components: {
    Portal,
    ItemActions,
    SimpleFilter,
    ReportList,
    Pagination,
    RenderForm,
    Sheet,
    StateWrapper,
    FileIcon,
    FileDetails,
    ReportCharts,
    ListItem,
    Tabs,
    ProcessHistory,
    SubWorkflowProcess,
    FormEntryHistory,
    // Stepper,
    Attachments,
    Comments,
    SaveSheet,
    FilterListType,
    ConfirmDelete,
    Breadcrumbs,
  },

  data() {
    return {
      mode: "REPORT",
      activeView: "TABLE",
      sortBy: {
        criteria: "",
        order: "ASC",
      },
      contentSearchText: "",
      groupBy: "",
      filterBy: [],
      selectedFilter: [],
      workflowId: 0,
      workflowList: [],
      workflowRequestList: [],
      workflowRequestListData: [],
      workflow: {},
      list: [],
      status: [
        {
          id: this.$nano.id(),
          label: "Running",
          value: "0",
        },
        {
          id: this.$nano.id(),
          label: "Completed",
          value: "1",
        },
      ],
      processLevel: [],
      userList: [],
      formId: "",
      form: null,
      formFields: [],
      formModel: {},
      itemModel: {},
      formSecureControls: [],
      workflowFormModel: {},
      saveAction: "",
      attachmentList: [],
      attachmentSheet: false,
      commentsList: [],
      commentsSheet: false,
      mailTransactionList: [],
      mailAttachmentSheet: false,
      mailAttachmentList: [],
      mailTransactionSheet: false,
      selectedProcess: {},
      selectedFile: {},
      fileSheet: false,
      checkList: [],
      rowIndex: null,
      hideFileSheet: false,
      processSheet: false,
      totalItems: 0,
      itemsPerPage: 10,
      currentPage: 1,
      title: "NO DATA",
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "mail transaction",
          value: "MAIL",
          icon: "mdi-email-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "print",
          value: "PRINT",
          icon: "mdi-printer",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "task",
          value: "TASK",
          icon: "eva-cube-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Data Validation",
          value: "VALIDATION",
          icon: "mdi-file-compare",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Sub Process",
          value: "SUB_PROCESS",
          icon: "eva-link-2-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Form Entry History",
          value: "FORM_ENTRY_HISTORY",
          icon: "mdi-file-document",
          count: 0,
        },
      ],
      mailTabs: [
        {
          id: this.$nano.id(),
          label: "outgoing",
          value: "OUTGOING",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "incoming",
          value: "INCOMING",
          count: 0,
        },
      ],
      mailTab: "OUTGOING",
      taskSheet: false,
      headerTitle: "",
      taskFormId: 0,
      taskRepositoryId: 0,
      addTaskForm: {},
      addTaskSheet: false,
      taskEntryId: [],
      taskEntries: [],
      taskEntryList: [],
      taskMode: "",
      fileDetailsSheet: false,
      fileData: [],
      stages: [],
      slaSettings: 0,
      repositoryId: 0,
      repositoryFields: [],
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      addTaskEnabled: false,
      loadingBarContent: false,
      chartRefresh: false,
      historyProcessId: 0,
      selectedSubWorkflow: {},
      formView: true,
      stageLevel: [],
      currentStep: 0,
      hasWflowInitiator: false,
      subWorkflowId: 0,
      initiateSubWorkflowProcess: false,
      subProcessSheet: false,
      subProcessData: {},
      subTabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
      ],
      subProcessLink: "",
      openSubProcessSheet: false,
      subProcessTickets: [],
      subProcessId: 0,
      showFormEntryHistoryModal: false,
      showAttachmentSheet: false,
      wform: {},
      repositoryDetails: {},
      showCommentsSheet: false,
      filterId: "",
      WfilterList: [],
      issavesheet: false,
      showFilterList: false,
      savedList: [],
      columnss: [],
      savedListData: [],
      isDeleteConfirmationVisible: false,
      deleteData: {},
      success: false,
      editData: [],
      breadcrumb: [],
      noFilter: false,
    };
  },

  computed: {
    columns() {
      let columns = [];

      columns.push(
        {
          id: this.$nano.id(),
          name: "id",
          label: "Process Id",
          dataType: "SHORT_TEXT",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
        },

        {
          id: this.$nano.id(),
          name: "requestNo",
          label: "Request No",
          type: "TITLE",
          dataType: "SHORT_TEXT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "flowStatus",
          label: "Status",
          dataType: "SINGLE_SELECT",
          options: this.status,
          isVisible: false,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "duration",
          label: "Duration",
          dataType: "SHORT_TEXT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "daysOpen",
          label: "Days Open",
          dataType: "SHORT_TEXT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "stage",
          label: "Stage",
          dataType: "SINGLE_SELECT",
          options: this.processLevel,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "actionUser",
          label: "Action User",
          dataType: "SINGLE_SELECT",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        }
      );
      if (this.slaSettings === 2) {
        columns.push(
          {
            id: this.$nano.id(),
            name: "resolutionTime",
            label: "Resolution Time",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          },
          {
            id: this.$nano.id(),
            name: "resolutionStatus",
            label: "Resolution Status",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          }
        );
      } else if (this.slaSettings === 3) {
        columns.push(
          {
            id: this.$nano.id(),
            name: "responseTime",
            label: "Response Time",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          },
          {
            id: this.$nano.id(),
            name: "responseStatus",
            label: "Response Status",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          }
        );
      } else if (this.slaSettings === 4) {
        columns.push(
          {
            id: this.$nano.id(),
            name: "responseTime",
            label: "Response Time",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          },
          {
            id: this.$nano.id(),
            name: "responseStatus",
            label: "Response Status",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          },
          {
            id: this.$nano.id(),
            name: "resolutionTime",
            label: "Resolution Time",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          },
          {
            id: this.$nano.id(),
            name: "resolutionStatus",
            label: "Resolution Status",
            dataType: "SHORT_TEXT",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
          }
        );
      }

      columns.push(
        {
          id: this.$nano.id(),
          name: "raisedBy",
          label: "Raised By",
          dataType: "SINGLE_SELECT",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "raisedAt",
          label: "Raised On",
          dataType: "DATE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "lastActionBy",
          label: "Last Action By",
          dataType: "SINGLE_SELECT",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "lastActionOn",
          label: "Last Action On",
          dataType: "DATE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        }
      );

      this.formFields.forEach((field) => {
        columns.push({
          id: this.$nano.id(),
          name: this.getLabel(field.value),
          label: field.label ? field.label : field.dataType,
          dataType: field.dataType,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        });
      });

      return columns;
    },

    _columns() {
      let columns = [];

      columns.push(
        {
          id: this.$nano.id(),
          name: "requestNo",
          label: "Request No",
          dataType: "SHORT_TEXT",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "flowStatus",
          label: "Status",
          dataType: "SINGLE_SELECT",
          options: this.status,
          isVisible: false,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "stage",
          label: "Stage",
          dataType: "SINGLE_SELECT",
          options: this.processLevel,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "actionUser",
          label: "Action User",
          dataType: "SINGLE_SELECT",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "raisedBy",
          label: "Raised By",
          dataType: "SINGLE_SELECT",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "raisedAt",
          label: "Raised On",
          dataType: "DATE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        }
      );

      this.formFields.forEach((field) => {
        columns.push({
          id: this.$nano.id(),
          name: field.value,
          label: field.label,
          dataType: field.dataType,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        });
      });

      return columns;
    },

    items() {
      return this.workflowRequestList.map(({ key, value }) => ({
        key: "",
        value: key,
        data: value.map((item) => ({
          icon:
            item.flowStatus == 1
              ? "mdi-check-circle-outline"
              : "mdi-progress-clock",
          color: item.flowStatus == 1 ? "green" : "orange",
          iconTooltip: item.flowStatus == 1 ? "Completed" : "In-progress",
          link: true,
          id: item.processId,
          processId: item.processId,
          requestNo: item.requestNo,
          raisedBy: item.raisedBy,
          raisedAt: this.$day.format(item.raisedAt),
          stage: item.stage,
          lastActionBy: item.transaction_createdByEmail,
          lastActionOn: this.$day.format(item.transaction_createdAt),
          actionUser: item.activityUserEmail,
          duration: item.flowStatus == 1 ? this.daysOpen(item) : "",
          daysOpen: item.flowStatus == 0 ? this.daysOpen(item) : "",
          attachmentCount: item.attachmentCount,
          commentsCount: item.commentsCount,
          jiraIssueInfo: item.jiraIssueInfo,
          ...this.getResponseData(item.slaResponse),
          ...this.getResolutionData(item.slaResolution),
          ...this.getFormData(item),
          subWorkflow: item.subworkflowTransaction.map((subItem) => ({
            activityId: subItem.activityId,
            userId: subItem.activityUserId,
            color: subItem.flowStatus == 1 ? "green" : "orange",
            iconTooltip: subItem.flowStatus == 1 ? "Completed" : "In-progress",
            icon:
              subItem.flowStatus == 1
                ? "mdi-check-circle-outline"
                : "mdi-progress-clock",
            processId: subItem.processId,
            raisedAt: this.$day.format(subItem.raisedAt),
            parentProcessID: item.processId,
            raisedBy: subItem.raisedBy,
            raisedByUser: subItem.raisedByUserId,
            requestNo: subItem.requestNo,
            review: subItem.review,
            stageName: subItem.stageName,
            id: subItem.subWorkFlowId,
            stageType: subItem.stageType,
            stage: subItem.stageName,
            subWorkFlowId: subItem.subWorkFlowId,
            subWorkflowName: subItem.subWorkflowName,
            transactionId: subItem.transactionId,
            inbox:
              String(subItem.activityUserId) ===
              String(this.$store.state.session.id),
            attachmentsCount: subItem.attachmentCount,
            commentsCount: subItem.commentsCount,
            displaySubTable: false,
            actionUser: subItem.activityUserEmail,
          })),
        })),
      }));
    },

    panels() {
      if (!this.form) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.form) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.form) {
        return {};
      }
      return this.form.settings;
    },

    checkListTemplate() {
      if (this.checkList.length) {
        return this.checkList.filter((row) => row.template).length > 0;
      }
      return false;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      if (!this.form) {
        return {};
      }
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (controls.indexOf(control) < 0) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  enableControls.push({
                    id: row.id,
                    parentControls: controls,
                  });
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },

    outgoingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Outgoing"
      );
    },

    incomingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Incoming"
      );
    },

    savedItems() {
      return this.savedListData.map(({ key, value }) => ({
        key: "",
        value: key,
        data: value.map((item) => ({
          icon: "mdi-text-box",
          id: item.id,
          itemId: item.itemId,
          link: true,
          name: item.name,
          description: item.description,
          createdBy: item.createdByEmail,
          createdon: this.$day.format(item.createdAt),
        })),
      }));
    },
  },

  watch: {
    workflowId: {
      deep: true,
      immediate: true,
      async handler() {
        this.currentPage = 1;
        this.workflowRequestList = [];
        this.workflowRequestListData = [];
        if (this.workflowId) {
          let selectedWorkflow = this.workflowList.find(
            (item) => item.value === this.workflowId
          );
          this.initiatedBy = selectedWorkflow.initiatedBy;
          this.formId = "";
          this.formFields = [];
          this.repositoryId = 0;
          this.repositoryFields = [];
          await this.getWorkflow();

          if (
            this.initiatedBy === "FORM" ||
            this.initiatedBy === "DOCUMENT_FORM"
          ) {
            if (selectedWorkflow.formId) {
              this.formId = String(selectedWorkflow.formId);
              await this.getForm();
            }
            if (selectedWorkflow.repositoryId) {
              this.repositoryId = selectedWorkflow.repositoryId;
              this.getRepository();
            }
          } else if (this.initiatedBy === "DOCUMENT") {
            this.repositoryId = selectedWorkflow.repositoryId;
            this.getRepository();
          }
          if (this.activeView === "TABLE") {
            this.getWorkflowData();
          }
        }
      },
    },

    itemsPerPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowId && this.itemsPerPage) {
          this.getWorkflowData();
        } else if (this.savedListData && this.itemsPerPage) {
          this.getFilterData();
        }
      },
    },

    currentPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowId && this.currentPage) {
          this.getWorkflowData();
        } else if (this.savedListData && this.currentPage) {
          this.getFilterData();
        }
      },
    },

    taskEntries: {
      immediate: true,
      handler() {
        this.tabs.find((tab) => {
          if (tab.value === "TASK") {
            tab.count = this.taskEntries.length;
          }
        });
      },
    },

    activeView: {
      deep: true,
      immediate: true,
      handler() {
        this.noFilter = false;
        if (this.workflowId && this.activeView === "TABLE") {
          this.getWorkflowData();
        }
        if (this.activeView === "CHART") {
          this.noFilter = true;
        } else {
          this.filterBy = [];
          this.workflowRequestList = [];
          this.workflowRequestListData = [];
        }
      },
    },

    filterId: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.filterId === "") {
          this.repositorySignReportList = [];
          this.workspaceId = 0;
          this.repositoryId = 0;
          this.filterBy = [];
        } else if (this.filterId === "-1") {
          this.breadcrumb = [];
          this.breadcrumb.push({
            id: 0,
            label: "Save Search",
            entity: "this.selectedEntity",
            type: "REPOSITORY ACTIVITY",
          });
          this.repositoryOcrReportList = [];
          this.showFilterList = true;
          this.getFilterData();
        } else if (this.filterId) {
          this.columnss = [];
          this.showFilterList = false;
          this.filterList();
          this.selectedIds = this.filterId;
        } else if (!this.filterId) {
          this.showFilterList = false;
          this.repositoryReportList = [];
        }
      },
    },
    success: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.success) {
          await this.getFilterList();
          if (this.filterId === "-1") {
            this.getFilterData();
          }
        }
      },
    },
  },

  created() {
    this.getWorkflows();
    this.getUsers();
    this.getTaskForm();
    this.getFilterList();
  },

  methods: {
    isTenantArmgroup() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return true;
      }
      return false;
    },

    checkTabAccess(value) {
      if (value === "TASK") {
        return this.addTaskEnabled;
      } else if (value === "VALIDATION") {
        if (this.isTenantArmgroup()) {
          return true;
        } else {
          return false;
        }
      } else if (value === "FORM_ENTRY_HISTORY") {
        if (this.formId) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },

    refreshData() {
      if (this.workflowId) {
        if (this.activeView === "TABLE") {
          this.getWorkflowData();
        } else {
          this.chartRefresh = true;
        }
      } else this.getWorkflows();
    },

    searchRow(search) {
      if (search) {
        this.workflowRequestList = this.workflowRequestListData.map(
          ({ key, value }) => ({
            key: key,
            value: value.filter((row) => {
              for (let cell in row) {
                let index = this.columns.findIndex((column) => {
                  return column.name === cell;
                });

                if (index >= 0 && row[cell]) {
                  if (
                    String(row[cell])
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return row;
                  }
                }
              }
            }),
          })
        );
      } else {
        this.workflowRequestList = this.workflowRequestListData;
      }
    },

    getFormData(item) {
      const data = {};
      if (item.formData) {
        for (let key of Object.keys(item.formData.fields)) {
          const label = this.getLabel(key);
          data[label] = this.removeTags(item.formData.fields[key]);
        }
      }
      return data;
    },

    getLabel(id) {
      let controlLabel = "";
      if (this.form) {
        this.form.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          let field = panel.fields.find((field) => field.id === id);
          if (field) {
            controlLabel = field.label ? field.label : field.type;
            return;
          }
        });
      }
      return controlLabel;
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    tableValue(value) {
      if (value) {
        return JSON.parse(value);
      }
      return [];
    },

    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    validateType(key) {
      let fieldType = "";
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        let formField = panel.fields.find((field) => field.id === key);
        if (formField) {
          fieldType = formField.type;
        }
      });
      return fieldType;
    },

    validateValue(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !urlPattern.test(value);
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://trial.ezofis.com") {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://app.ezofis.com") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            }
          } else if (origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/1`;
          }
        } else {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
            } else if (
              origin === "https://trial.ezofis.com" &&
              !otherFilePDFSupport(ext)
            ) {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            } else if (origin === "https://app.ezofis.com") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2`;
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
        }
      }
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },

    download(id) {
      window.open(
        `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${id}`
      );
    },

    downloadAll() {
      this.checkList.forEach((row) => {
        if (row.id && row.template) {
          window.open(
            `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${row.id}`
          );
        }
      });
    },

    attachTemplate(uid) {
      this.rowIndex = uid;
      this.$refs.attachments.click();
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    daysOpen(request) {
      let timeDiff;
      if (request.stageType === "END") {
        timeDiff =
          new Date(request.transaction_createdAt).getTime() -
          new Date(request.raisedAt).getTime();
      } else {
        timeDiff = new Date().getTime() - new Date(request.raisedAt).getTime();
      }
      let days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

      if (days) {
        if (days === 1) {
          return `${days} day`;
        }
        return `${days} days`;
      } else if (hours) {
        if (hours === 1) {
          return `${hours} hour`;
        }
        return `${hours} hours`;
      } else if (minutes) {
        if (minutes === 1) {
          return `${minutes} min`;
        }
        return `${minutes} mins`;
      }
      return "";
    },

    getJiraIssueInfo(data) {
      if (data.jiraIssueInfo.id) {
        return {
          "Ref# Id": data.jiraIssueInfo.id,
          Key: data.jiraIssueInfo.key,
          Link: data.jiraIssueInfo.self
            ? this.getJIRALink(data.jiraIssueInfo)
            : "",
          Assignee: data.jiraIssueInfo.assignee,
          "Issue Status": data.jiraIssueInfo.issueStatus,
        };
      }
    },

    getJIRALink(link) {
      let url = new URL(link.self);
      let origin = url.origin.replace(
        "https://jira-external-tools-integration.getgotools.net",
        "https://jira.ops.expertcity.com"
      );
      return `${origin}/browse/${link.key}`;
    },

    getResponseData(data, excel) {
      let sla = {
        responseTime: "",
        responseStatus: "",
      };
      let timeDiff;
      if (data) {
        sla.responseStatus = data.status || 0;
        if (sla.responseStatus == 0) {
          timeDiff = new Date(data.assignedAt).getTime() - new Date().getTime();
        } else if (sla.responseStatus == 1) {
          timeDiff =
            new Date(data.assignedAt).getTime() -
            new Date(data.responsedAt).getTime();
        } else if (sla.responseStatus == 2) {
          if (data.responsedAt) {
            timeDiff =
              new Date(data.responsedAt).getTime() -
              new Date(data.assignedAt).getTime();
          } else {
            timeDiff =
              new Date().getTime() - new Date(data.assignedAt).getTime();
          }
        }
        let days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        if (days) {
          if (days === 1) {
            sla.responseTime = `${days} day`;
          }
          sla.responseTime = `${days} days`;
        } else if (hours) {
          if (hours === 1) {
            sla.responseTime = `${hours} hour`;
          }
          sla.responseTime = `${hours} hours`;
        } else if (minutes) {
          if (minutes === 1) {
            sla.responseTime = `${minutes} min`;
          }
          sla.responseTime = `${minutes} mins`;
        }
      }
      if (excel) {
        if (data) {
          return {
            "Response Time": sla.responseTime,
            "Response Status":
              sla.responseStatus == 0
                ? "In-Progress"
                : sla.responseStatus == 1
                ? "WithIn SLA"
                : "Overdue",
          };
        } else {
          return {};
        }
      }
      return sla;
    },

    getResolutionData(data, excel) {
      let sla = {
        resolutionTime: "",
        resolutionStatus: "",
      };
      let timeDiff;
      if (data) {
        sla.resolutionStatus = data.status || 0;
        if (sla.resolutionStatus == 0) {
          timeDiff = new Date(data.assignedAt).getTime() - new Date().getTime();
        } else if (sla.resolutionStatus == 1) {
          timeDiff =
            new Date(data.assignedAt).getTime() -
            new Date(data.responsedAt).getTime();
        } else if (sla.resolutionStatus == 2) {
          if (data.responsedAt) {
            timeDiff =
              new Date(data.responsedAt).getTime() -
              new Date(data.assignedAt).getTime();
          } else {
            timeDiff =
              new Date().getTime() - new Date(data.assignedAt).getTime();
          }
        }
        let days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        if (days) {
          if (days === 1) {
            sla.resolutionTime = `${days} day`;
          }
          sla.resolutionTime = `${days} days`;
        } else if (hours) {
          if (hours === 1) {
            sla.resolutionTime = `${hours} hour`;
          }
          sla.resolutionTime = `${hours} hours`;
        } else if (minutes) {
          if (minutes === 1) {
            sla.resolutionTime = `${minutes} min`;
          }
          sla.resolutionTime = `${minutes} mins`;
        }
      }
      if (excel) {
        if (data) {
          return {
            "Resolution Time": sla.resolutionTime,
            "Resolution Status":
              sla.resolutionStatus == 0
                ? "In-Progress"
                : sla.resolutionStatus == 1
                ? "WithIn SLA"
                : "Overdue",
          };
        } else {
          return {};
        }
      }
      return sla;
    },

    checkDocument() {
      this.docWarning = false;
      if (this.trackRequest) {
        return;
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          if (row.required && !row.attach) {
            this.docWarning = true;
          }
        });
      }
    },

    async export_Data() {
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await report.getWorkflowData(this.workflowId, {
        filterBy: this.filterBy,
        itemsPerPage: 0,
        currentPage: 0,
        mode: "",
      });

      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { meta, data } = payload;
      if (meta.totalItems && data.length) {
        let exportRecords = [];
        data[0].value.forEach((item) => {
          exportRecords.push({
            "Request No": item.requestNo,
            ...this.getJiraIssueInfo(item),
            Status: item.flowStatus == 1 ? "Completed" : "In-progress",
            Duration: item.flowStatus == 1 ? this.daysOpen(item) : "",
            "Days Open": item.flowStatus == 0 ? this.daysOpen(item) : "",
            Stage: item.stage,
            "Action User": item.activityUserEmail,
            ...this.getResponseData(item.slaResponse, true),
            ...this.getResolutionData(item.slaResolution, true),
            "Raised By": item.raisedBy,
            "Raised On": this.$day.format(item.raisedAt),
            "Last Action By": item.transaction_createdByEmail,
            "Last Action On": this.$day.format(item.transaction_createdAt),
            "Attachments Count": item.attachmentCount,
            "Comments Count": item.commentsCount,
            ...this.getFormData(item),
          });
        });
        let selectedWorkflow = this.workflowList.find(
          (item) => item.value === this.workflowId
        );
        exportData(exportRecords, selectedWorkflow.label + "-Report");
      } else {
        //this.$alert.info("No data found for export");
      }
    },

    closeTaskSheet() {
      this.taskSheet = false;
    },

    openHistory(request) {
      this.selectedProcess = request;
      this.showLinks("HISTORY");
    },

    openAttachments(request) {
      this.selectedProcess = request;
      this.showLinks("ATTACHMENTS");
    },

    openComments(request) {
      this.selectedProcess = request;
      this.showLinks("COMMENTS");
    },

    showMailAttachments(mail) {
      this.mailAttachmentList = [];
      let files = mail.attachmentJson.split(",");
      files.forEach((file) => {
        let filename = file.split("\\").pop();
        this.mailAttachmentList.push({
          id: this.$nano.id(),
          mailId: mail.id,
          name: filename,
          createdAt: mail.createdAt,
        });
      });
      this.mailAttachmentSheet = true;
    },

    openAttachmentFile(file) {
      let link = `${process.env.VUE_APP_API_URL}/client/viewAttachment/${this.$store.state.session.tenantId}/${file.mailId}/${file.name}`;
      window.open(link, "_blank");
    },

    getColor(label) {
      if (label === "Closed" || label === "High") {
        return "red";
      } else if (label === "In Progress" || label === "Medium") {
        return "warning";
      } else if (label === "Open" || label === "Low") {
        return "secondary";
      } else if (label === "ReOpen") {
        return "primary";
      } else {
        return "primary";
      }
    },

    updateSelectedSubWorkflow(item) {
      this.subProcessData = item;
      this.selectedSubWorkflow = item;
      this.subWorkflowId = item.id;
      this.openSubProcessSheet = true;
    },

    showSubHistory(request) {
      // this.$emit("subHistory", subProcessId, mainWfProcessId);
      this.selectedProcess = request;
      this.showLinks("HISTORY");
    },

    // API FUNCTIONS

    async getWorkflows() {
      this.workflowList = [];
      let filters = [
        {
          filters: [
            {
              id: this.$nano.id(),
              criteria: "flowStatus",
              condition: "IS_EQUALS_TO",
              value: "PUBLISHED",
            },
          ],
          groupCondition: "",
        },
      ];
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getWorkflows({
        mode: "BROWSE",
        sortBy: {
          criteria: "",
          order: "DESC",
        },
        groupBy: this.groupBy,
        filterBy: filters,
      });
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        if (payload.data.length) {
          payload.data[0].value.forEach((workflow) => {
            this.workflowList.push({
              id: this.$nano.id(),
              label: workflow.name,
              value: workflow.id,
              initiatedBy: workflow.initiatedBy,
              formId: workflow.wFormId,
              repositoryId: workflow.repositoryId,
              flowJson: JSON.parse(workflow.flowJson),
            });
          });
        }
      }
      if (this.workflowList.length === 1) {
        this.workflowId = this.workflowList[0].value;
      }
    },

    async getTaskForm() {
      this.taskFormList = [];
      this.addTaskEnabled = false;
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "Task",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          this.addTaskEnabled = true;
          data[0].value.forEach((task) => {
            this.taskFormList.push({
              id: task.id,
              label: task.name,
              value: task.id,
              repositoryId: task.repositoryId,
              formJson: task.formJson,
            });
          });
          if (this.taskFormList.length) {
            if (this.taskFormList.length === 1) {
              this.taskFormId = this.taskFormList[0].id;
              this.taskRepositoryId = this.taskFormList[0].repositoryId;
              this.addTaskForm = JSON.parse(this.taskFormList[0].formJson);
            }
          } else {
            //this.$alert.info("Task form not found");
          }
        }
      }
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value,
            value: String(user.id),
          });
        });
      }
    },

    async getWorkflow() {
      this.processLevel = [];
      this.stages = [];
      this.checkList = [];
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await workflow.getWorkflow(this.workflowId);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.workflow = payload;
      let workflowJson = JSON.parse(this.workflow.flowJson);
      this.slaSettings = workflowJson.hasSLASettings;

      workflowJson.blocks.forEach((block) => {
        if (
          block.type === "START" ||
          block.type === "INTERNAL_ACTOR" ||
          block.type === "END"
        ) {
          let levels = [];
          levels.push({
            id: block.id,
            label: block.settings.label,
            type: block.type,
          });

          let icon = "",
            color = "";

          if (block.type === "START") {
            icon = "mdi-flag-outline";
            color = "green";
          } else if (block.type === "INTERNAL_ACTOR") {
            if (block.settings.users.length && block.settings.groups.length) {
              icon = "mdi-account-group-outline";
              color = "secondary";
            } else if (block.settings.users.length) {
              icon = "mdi-account-outline";
              color = "primary";
            } else if (block.settings.groups.length) {
              icon = "mdi-account-group-outline";
              color = "secondary";
            } else {
              icon = "mdi-account-group-outline";
              color = "secondary";
            }
          } else if (block.type === "END") {
            icon = "mdi-stop-circle-outline";
            color = "red";
          }

          levels.forEach((level) => {
            this.processLevel.push({
              id: this.$nano.id(),
              key: level.id,
              label: level.label,
              value: level.label.toLowerCase(),
            });
            this.stages.push({
              id: this.$nano.id(),
              key: level.id,
              label: level.label,
              icon: icon,
              color: color,
              type: level.type,
            });
          });
        }
      });

      let startBlock = workflowJson.blocks.find(
        (block) => block.type === "START"
      );

      if (startBlock) {
        if (startBlock.settings.fileSettings) {
          if (startBlock.settings.fileSettings.fileCheckList.length) {
            this.checkList = startBlock.settings.fileSettings.fileCheckList;
            this.checkList.forEach((row) => {
              row.uid = this.$nano.id();
              row.attach = false;
            });
            this.checkDocument();
          }
        }
      }
    },

    async getForm() {
      this.formFields = [];
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await form.getForm(this.formId);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      let formJson = JSON.parse(payload.formJson);
      this.form = formJson;
      this.wform = payload;
      this.formId = String(this.formId);
      const panels = [...formJson.panels, ...formJson.secondaryPanels];

      if (this.form.settings.general.layout === "CARD") {
        this.form.settings.general.layout = "TAB";
      }

      if (!panels.length) {
        return;
      }
      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      fields.forEach((field) => {
        if (
          field.type !== "DIVIDER" &&
          field.type !== "LABEL" &&
          field.type !== "PARAGRAPH" &&
          (field.label || field.type === "TABLE") &&
          field.settings.general.visibility !== "DISABLE"
        ) {
          this.formFields.push({
            id: this.$nano.id(),
            label: field.label ? field.label : field.type,
            value: field.id,
            dataType: field.type,
          });
        }
      });
    },

    async getRepository() {
      this.repositoryFields = [];

      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      let repositoryDetails = payload;
      this.repositoryFields = repositoryDetails.fields;
      this.repositoryDetails = repositoryDetails;
    },

    async getWorkflowData() {
      // this.$store.commit("showLoadingBarPage");
      this.loadingBarContent = true;
      this.workflowRequestList = [];
      this.workflowRequestListData = [];
      this.list = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (
              row.dataType === "SINGLE_SELECT" ||
              row.dataType === "SINGLE_CHOICE"
            ) {
              row.value = JSON.stringify([row.arrayValue]);
            }
          });
        }
      });
      const { error, payload } = await report.getWorkflowData(this.workflowId, {
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        mode: "",
      });
      // this.$store.commit("hideLoadingBarPage");
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          this.list.push({
            ...process,
          });
        });
      });

      this.totalItems = payload.meta?.totalItems || 0;

      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.workflowRequestList = [];
        this.workflowRequestListData = [];
      } else {
        this.workflowRequestList = payload.data;
        this.workflowRequestListData = payload.data;
      }
    },

    async openProcess(request) {
      this.selectedProcess = request;
      this.formModel = {};
      this.itemModel = {};
      if (this.selectedProcess.formData) {
        let keys = Object.keys(this.selectedProcess.formData.fields);
        let newValues = {};
        keys.forEach((key) => {
          newValues[key] = this.validateTypeValue(
            this.selectedProcess.formData.fields[key]
          );
        });
        this.formModel = newValues;
      } else if (this.selectedProcess.itemData) {
        this.itemModel = this.selectedProcess.itemData;
      }
      this.headerTitle = `${this.selectedProcess.requestNo} - ${request.stage}`;
      this.processSheet = true;
      this.getTaskList();
      this.getComments();
      this.getAttachments();
      this.getMailTransactions();
      this.getSubWorkflow();
      // this.showHistoryStepper(
      //   this.selectedProcess.processId,
      //   this.selectedProcess.id
      // );
    },

    itemLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}`;
      } else if (origin === "https://trial.ezofis.com") {
        return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}&action=download,print`;
      } else if (origin === "https://app.ezofis.com") {
        return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}&action=download,print`;
      } else {
        return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}&action=download,print`;
      }
    },

    async showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        await this.getAttachments();
        // this.attachmentSheet = true;
        this.showAttachmentSheet = true;
      } else if (tab === "COMMENTS") {
        await this.getComments();
        // this.commentsSheet = true;
        this.showCommentsSheet = true;
      } else if (tab === "HISTORY") {
        this.historyProcessId = this.selectedProcess.processId;
      } else if (tab === "PRINT") {
        if (this.selectedProcess.formData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/form/generatePdfPrint/${this.$store.state.session.tenantId}/${this.workflow.id}/${this.selectedProcess.processId}/${this.formId}/${this.selectedProcess.formData.formEntryId}/1`
          );
        } else if (this.selectedProcess.itemData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedProcess.itemData.repositoryId}/${this.selectedProcess.itemData.itemId}/2`
          );
        }
        //window.print();
      } else if (tab === "TASK") {
        if (this.taskEntries.length) {
          this.taskSheet = true;
        } else {
          this.$alert.info("Task not found");
        }
      } else if (tab === "MAIL") {
        this.getMailTransactions();
        this.mailTransactionSheet = true;
      } else if (tab === "SUB_PROCESS") {
        this.subProcessSheet = true;
      } else if (tab === "FORM_ENTRY_HISTORY") {
        this.showFormEntryHistoryModal = true;
      }
    },

    async getComments() {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getProcessComments(
        this.selectedProcess.id,
        this.selectedProcess.processId
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.commentsList = [];
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async getAttachments() {
      this.checkList = [];
      this.attachmentList = [];
      if (this.selectedProcess.processId) {
        let workflowJson = JSON.parse(this.workflow.flowJson);
        let block = workflowJson.blocks.find(
          (block) => block.id === this.selectedProcess.activityId
        );
        if (block) {
          if (block.settings.fileSettings) {
            if (block.settings.fileSettings.fileCheckList.length) {
              this.checkList = block.settings.fileSettings.fileCheckList;
              this.checkList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
              });
              this.checkDocument();
            }
          }
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = 0;
          }
        });
        this.$store.commit("showLoadingBarPage");
        const { error, payload } = await workflow.getAttachments(
          this.workflowId,
          this.selectedProcess.processId
        );

        this.$store.commit("hideLoadingBarPage");

        if (error) {
          this.$alert.error(error);
          return;
        }
        this.attachmentList = [];
        if (payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
        }
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            let filterAttach = this.attachmentList.filter((file) => {
              let filename = file.name.substr(0, file.name.lastIndexOf("."));
              if (row.name === filename) {
                return file;
              }
            });
            if (filterAttach.length) {
              row.attach = true;
            } else {
              row.attach = false;
            }
          });
        }
      }
    },

    async getTaskList() {
      this.taskEntries = [];
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getTaskEntries(
        this.workflowId,
        this.selectedProcess.processId,
        {
          filterBy: [
            {
              filters: [
                {
                  criteria: "createdBy",
                  condition: "IS_EQUALS_TO",
                  value: this.$store.state.session.id,
                },
              ],
            },
          ],
        }
      );
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        const panels = [
          ...this.addTaskForm.panels,
          ...this.addTaskForm.secondaryPanels,
        ];

        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        this.taskList = payload;
        payload.forEach((row) => {
          let taskName = "",
            taskAssign = "",
            startDate = "",
            endDate = "",
            priority = "",
            status = "";
          formFields.forEach((field) => {
            switch (field.label) {
              case "Task Name":
                taskName = row.entryInfo[0][field.id];
                break;
              case "Task Assign":
                taskAssign = row.entryInfo[0][field.id];
                break;
              case "Start Date":
                startDate = row.entryInfo[0][field.id];
                break;
              case "End Date":
                endDate = row.entryInfo[0][field.id];
                break;
              case "Priority":
                priority = row.entryInfo[0][field.id];
                break;
              case "Task Status":
                status = row.entryInfo[0][field.id];
                break;
            }
          });
          this.taskEntries.push({
            id: row.entryInfo[0].itemid,
            name: taskName,
            assigned: taskAssign,
            startDate: startDate,
            endDate: endDate,
            priority: priority,
            status: status,
          });
        });
      }
    },

    async getData(fromDate, toDate) {
      const filterBy = [
        {
          id: this.$nano.id(),
          filters: [
            {
              id: this.$nano.id(),
              criteria: "raisedAt",
              condition: "IS_EQUALS_TO",
              value: fromDate,
              valueTo: toDate,
            },
          ],
          groupCondition: "",
        },
      ];

      this.$store.commit("showLoadingBarPage");
      this.workflowRequestList = [];
      this.workflowRequestListData = [];
      this.list = [];
      const { error, payload } = await report.getWorkflowData(this.workflowId, {
        filterBy: filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        mode: "",
      });
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          this.list.push({
            ...process,
          });
        });
      });

      this.totalItems = payload.meta?.totalItems || 0;

      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.workflowRequestList = [];
        this.workflowRequestListData = [];
      } else {
        this.workflowRequestList = payload.data;
        this.workflowRequestListData = payload.data;
      }
    },

    async getFileData(file) {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.id,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      this.fileData = payload;
      this.fileDetailsSheet = true;
    },

    processDocumentAction(action) {
      if (action.id === "Email_Sharing") {
        //
      } else if (action.id === "Print") {
        if (this.itemModel.name) {
          let ext = this.itemModel.name.split(".").pop();
          if (pdfSupport(ext)) {
            localStorage.setItem("filePrint", true);
            localStorage.setItem("filePrintId", this.itemModel.itemId);
          } else {
            this.$refs.myProcessFiles.contentWindow.print();
          }
        }
        this.processFilePrintSession();
      } else if (action.id === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.itemModel.repositoryId}/${this.itemModel.itemId}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async processFilePrintSession() {
      this.$store.commit("showLoadingBarPage");

      const { error } = await menu.filePrintSession({
        repositoryId: this.itemModel.repositoryId,
        itemId: this.itemModel.itemId,
        activityId: 4,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    fileMenuAction(fileObj, action) {
      this.selectedFile = fileObj;
      this.documentAction(action, true);
    },

    documentAction(action, viewPrint) {
      if (action === "Email_Sharing") {
        //
      } else if (action === "Print") {
        if (this.selectedFile.name) {
          if (viewPrint) {
            let link = "";
            if (this.selectedFile.initiate) {
              let type = 1;
              if (
                this.repositoryDetails.fieldsType === "STATIC" &&
                this.workflow.initiatedBy === "FORM"
              ) {
                type = 2;
              }
              link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/1`;
            } else {
              link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
            }
            window.open(link, "_blank");
          } else {
            let ext = this.selectedFile.name.split(".").pop();
            if (pdfSupport(ext)) {
              localStorage.setItem("filePrint", true);
              localStorage.setItem("filePrintId", this.selectedFile.id);
            } else {
              this.$refs.myAttachmentFiles.contentWindow.print();
            }
          }
        }
        this.filePrintSession();
      } else if (action === "Download") {
        let link = "";

        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/2`;
        } else {
          link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        }
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getMailTransactions() {
      this.mailTransactionList = [];
      this.tabs.find((tab) => {
        if (tab.value === "MAIL") {
          tab.count = 0;
        }
      });
      let filter = { filterBy: [] };
      const { error, payload } = await workflow.getMailTransactions(
        this.workflow.id,
        this.selectedProcess.processId,
        filter
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.mailTransactionList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "MAIL") {
            tab.count = this.mailTransactionList.length;
          }
        });
      }
    },

    async resendMail(mailId) {
      if (mailId) {
        const { error } = await workflow.resendMail(mailId);
        if (error) {
          this.$alert.error(error);
          return;
        }
        this.$alert.success("Resend Successful!");
        this.getMailTransactions();
      } else {
        this.$alert.info("Unable to resend");
      }
    },

    async openSubAttachments(request) {
      console.log(
        this.workflowRequestList[0].value,
        "this.workflowRequestList[0].value"
      );
      const mainWorkflow = this.workflowRequestList[0].value.find(
        (row) => row.processId === request.parentProcessID
      );
      let subWorkflow;
      if (mainWorkflow) {
        subWorkflow = mainWorkflow.subworkflowTransaction.find(
          (subRow) => subRow.processId === request.processId
        );
        if (subWorkflow) {
          subWorkflow.id = subWorkflow.subWorkFlowId;
          this.selectedProcess = subWorkflow;
          // this.$emit("update:workflow", subWorkflow);
          this.$emit("update:selectedProcess", subWorkflow);
          // await this.getRepository();
          await this.getAttachments(
            subWorkflow.subWorkFlowId,
            subWorkflow.processId
          );
          this.showLinks("ATTACHMENTS");
        }
      }
    },

    async openSubComments(request) {
      const mainWorkflow = this.workflowRequestList[0].value.find(
        (row) => row.processId === request.parentProcessID
      );
      let subWorkflow;
      if (mainWorkflow) {
        subWorkflow = mainWorkflow.subworkflowTransaction.find(
          (subRow) => subRow.processId === request.processId
        );
        if (subWorkflow) {
          subWorkflow.id = subWorkflow.subWorkFlowId;
          this.selectedProcess = subWorkflow;
          // this.$emit("update:workflow", subWorkflow);
          // this.$emit("update:selectedProcess", subWorkflow);
          await this.getComments(
            subWorkflow.subWorkFlowId,
            subWorkflow.processId
          );
          this.showLinks("COMMENTS");
        }
      }
    },

    async showHistoryStepper(processId, wId) {
      console.log("called");
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.processHistory(wId, processId);
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.length) {
        this.stageLevel = [];
        this.currentStep = 0;
        // console.log(payload);
        payload.forEach((row) => {
          if (row.actionStatus !== 2) {
            if (
              this.stageLevel.filter((stage) => stage.id === row.activityId)
                .length === 0
            ) {
              this.stageLevel.push({
                id: row.activityId,
                label: row.stage,
                status: row.status,
              });
              if (
                ((row.actionUser || row.subWorkflowHistory) &&
                  row.status &&
                  row.status !== "Forward") ||
                (row.actionUser === null && !row.subWorkflowHistory)
              ) {
                this.currentStep += 1;
              }
            } else if (row.status) {
              if (
                this.stageLevel.filter(
                  (stage) =>
                    stage.id === row.activityId && stage.status !== row.status
                ).length
              ) {
                this.currentStep += 1;
              } else {
                this.currentStep -= 1;
              }
            } else {
              let index = this.stageLevel.findIndex(
                (stage) => stage.id === row.activityId
              );
              if (index > -1) {
                this.stageLevel.splice(index, this.stageLevel.length - index);
                this.stageLevel.push({
                  id: row.activityId,
                  label: row.stage,
                  status: row.status,
                });
                if (
                  (row.actionUser && row.status && row.status !== "Forward") ||
                  row.actionUser === null
                ) {
                  this.currentStep = index + 1;
                } else {
                  this.currentStep = index;
                }
              }
            }
          }
        });
      }
    },

    async getSubWorkflow() {
      this.tabs.find((tab) => {
        if (tab.value === "SUB_PROCESS") {
          tab.count = 0;
        }
      });

      const { error, payload } = await workflow.getSubWorkflow(
        this.workflowId,
        this.selectedProcess.processId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload, "payload");

      if (payload.length) {
        this.getSubProcessDetails(payload[0].subWorkflowId, payload);
      }
    },

    async getSubProcessDetails(wId, data) {
      this.subProcessTickets = [];
      const { error, payload } = await report.getWorkflowData(wId, {
        filterBy: [],
        itemsPerPage: 0,
        currentPage: 0,
        mode: "",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload);
      if (payload.meta.totalItems) {
        // console.log(payload);
        let process = [];
        data.forEach((sub) => {
          let hasProcess = payload.data[0].value.find(
            (item) => item.processId === Number(sub.subProcessId)
          );
          // console.log(hasProcess);
          if (hasProcess) {
            process.push(hasProcess);
          }
        });

        if (process.length) {
          this.subProcessTickets = process;
        }
        this.tabs.find((tab) => {
          if (tab.value === "SUB_PROCESS") {
            tab.count = process.length;
          }
        });
      }
    },

    subProcessShowLinks(value, process) {
      this.subProcessLink = "";
      this.subProcessData = process;
      this.subProcessLink = value;
    },

    openSubProcess(process) {
      this.subProcessData = process;
      this.openSubProcessSheet = true;
    },

    closeSubProcess() {
      this.openSubProcessSheet = false;
      this.subProcessData = {};
      this.initiateSubWorkflowProcess = false;
      // this.subProcessTickets = [];
    },

    closeSubProcessSheet() {
      this.subProcessSheet = false;
    },

    attachmentCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = count;
        }
      });
    },

    commentsCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = count;
        }
      });
    },

    async getFilterData() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBarPage");
      this.savedList = [];
      this.savedListData = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = row.arrayValue;
            }
          });
        }
      });
      // const { error, payload } = await report.getRepositoryData(
      //   this.repositoryId,
      //   {
      //     itemId: this.itemId,
      //     filterBy: this.filterBy,
      //     itemsPerPage: this.itemsPerPage,
      //     currentPage: this.currentPage,
      //   }
      // );
      // this.$store.commit("hideLoadingBarPage");
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "workflowReport",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.columnss = [
        {
          id: this.$nano.id(),
          name: "id",
          label: "Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "itemId",
          label: "Item Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "createdon",
          label: "Created On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: "DATE",
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: "SHORT_TEXT",
        },
      ];

      this.loadingBarContent = false;
      // if (payload.data.length) {
      //   payload.data.forEach((row) => {
      //     row.value.forEach((process) => {
      //       this.SignReportList.push({
      //         ...process,
      //       });
      //     });
      //   });
      // }
      this.totalItems = payload.meta?.totalItems || 0;
      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.savedList = [];
        this.savedListData = [];
      } else {
        this.savedList = payload.data;
        this.savedListData = payload.data;
        if (this.savedList.length) {
          this.exportDataList = true;
        }
      }
      console.log(this.savedListData, "savedListData");
    },

    async getFilterList() {
      this.success = false;
      this.WfilterList = [];
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "workflowReport",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }

      let data = payload.data[0].value;
      this.listedData = data;

      let List = data.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        value: filter.name,
      }));

      const removeObserver = (obj) => {
        const newObj = {};
        Object.keys(obj).forEach((key) => {
          if (key !== "__ob__") {
            newObj[key] = obj[key];
          }
        });
        return newObj;
      };
      const arrayOfObjects = List.map((obj) => removeObserver(obj));
      this.WfilterList = [
        { id: this.$nano.id(), label: "Show All", value: "-1" },
        ...arrayOfObjects,
      ];
      console.log(this.WfilterList);
    },

    async filterList() {
      this.listedData.map((item) => {
        if (item.name === this.filterId) {
          // let search = item.filterJson;
          // const filterItem = search.slice(1, -1);
          const parsedArray = JSON.parse(item.filterJson);
          let filters = [];
          if (parsedArray[0].filters) {
            filters = JSON.parse(parsedArray[0].filters);
          }
          console.log(parsedArray, "parsedArray");
          this.filterListData = {
            id: item.itemId,
            name: item.name,

            rId: parsedArray[0].uId,
            filterFrom: item.filterFrom,
            filters: filters,
          };
        }
      });
      this.filterFrom = this.filterListData.filterFrom;
      this.workflowId = this.filterListData.rId;
      this.itemId = 0;
      this.filterBy = this.filterListData.filters;
      this.getWorkflowData();
    },
    deleteItem(items) {
      this.savedListData[0].value.map((item) => {
        if (item.id === items) {
          console.log(item, "itemd");
          this.isDeleteConfirmationVisible = true;
          this.deleteData = {
            id: item.id,
            name: item.name,
            description: item.description,
            filterJson: item.filterJson,
            filterFrom: item.filterFrom,
            createdAt: item.createdAt,
            modifiedAt: item.modifiedAt,
            createdBy: item.createdBy,
            modifiedBy: item.modifiedBy,
            isDeleted: true,
          };
        }
      });
    },
    async openFilter(rowId) {
      this.showFilterList = false;
      this.filterId = "0";

      this.listedData.map((item) => {
        if (item.id === rowId) {
          // let search = item.filterJson;

          // const filterItem = search.slice(1, -1);
          const parsedArray = JSON.parse(item.filterJson);
          let filters = [];
          if (parsedArray[0].filters) {
            filters = JSON.parse(parsedArray[0].filters);
          }
          console.log(parsedArray, "parsedArray");
          this.filterListData = {
            id: item.itemId,
            name: item.name,

            rId: parsedArray[0].uId,
            filterFrom: item.filterFrom,
            filters: filters,
          };
        }
      });
      this.filterFrom = this.filterListData.filterFrom;
      this.workflowId = this.filterListData.rId;
      this.itemId = 0;
      this.filterBy = this.filterListData.filters;
      this.getWorkflowData();
    },
    edit(items) {
      this.editData = [];
      this.savedListData[0].value.map((item) => {
        if (item.id === items) {
          this.issavesheet = true;
          this.editData = [
            {
              id: item.id,
              name: item.name,
              description: item.description,
              filterJson: item.filterJson,
              filterFrom: item.filterFrom,
              createdAt: item.createdAt,
              modifiedAt: item.modifiedAt,
              createdBy: item.createdBy,
              modifiedBy: item.modifiedBy,
              dashboard: item.pinDashboard,
              assignTo: item.assignTo,
            },
          ];
        }
      });
    },
    saveSheet() {
      this.editData = [];
      this.issavesheet = true;
    },
    homeRepository() {
      this.filterId = "0";
      this.getFilterList();
      this.breadcrumb = [];
    },
  },
};
</script>

<style lang="scss" scoped>
#workflow-reports {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 300px);
  }
}

#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .attachmentList,
#sheet .checkList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#modal #processHistory {
  #history {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    td.ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }

    .comments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    .attachments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    #comments1 {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      // margin-bottom: 15px;
      background: #fcfafa;
      table-layout: fixed;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;

          &.small {
            width: 70px;
          }

          &.medium {
            width: 90px;
          }

          &.large {
            width: 210px;
          }
        }
      }

      th {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;

        &.commentsText {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.commentsText:hover {
          overflow: visible;
          white-space: normal;
          word-break: break-all;
        }
      }
    }

    #attachments {
      width: 98%;
      border-collapse: collapse;
      white-space: nowrap;
      margin-bottom: 5px;
      background: #fcfafa;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      .comments {
        color: var(--secondary);
        text-align: left;
        cursor: pointer;
      }
    }
  }
}

#sheet .mailTransactionList {
  padding: 12px;

  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--divider-color);
  }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

#sheet .mailTransactionList {
  #action-btn {
    width: 28px !important;
    height: 28px !important;
  }

  .text-underline {
    text-decoration: underline;
  }
}

.loading-content {
  background-color: var(--body-bg-color) !important;
  position: absolute;
  z-index: 9999;
}

#sheet {
  #form-view {
    background-color: var(--body-bg-color);
    margin: auto;
    padding: 16px;
  }
}

#requestDetails {
  table {
    &.submittedData {
      background: $primary-1;
    }
    &.mainData {
      table-layout: fixed;
    }
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--divider-color);

    tr {
      height: 36px;

      th {
        color: $primary-11;
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color);
    }

    tr:not(:last-child) {
      td,
      th {
        border-bottom: 1px solid var(--divider-color);
      }
    }

    .tblHeader {
      color: $primary-11;
      font-weight: 500;
      &.small {
        width: 200px;
      }
    }
  }

  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .text-underline {
    text-decoration: underline;
  }
}

#sheet .subProcess {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }

  .text-underline {
    text-decoration: underline;
  }
}
</style>
